import { getChildrens, getCriticalMomentsValues, getLeaves, selectedCriticalMoments } from "@utils/CriticalMomentUtils"
import { selectedGeos } from "@utils/GeoUtils"
import _, { isArray, isNumber, isString } from "lodash"
import Immutable from 'seamless-immutable'
import useClientConfig from "../useClientConfig"

export const answeredMapper = (data: any): any => {
  //console.log('Answered mapper')
  //console.log(data)
  let answered: any = data !== undefined && data.find((item: any) => item['group'] == 'ANSWERED')
  answered = answered && answered['count'] !== undefined ? answered['count'] : 0

  let inProgress: any = data !== undefined && data.find((item: any) => item['group'] == 'IN_PROGRESS')
  inProgress =
    inProgress && inProgress['count'] !== undefined ? inProgress['count'] : 0
  const total = answered + inProgress
  //return inProgress > 0 ? { value: (answered / inProgress) * 100 } : []
  return total > 0 ? { value: (answered / total) * 100, total: total } : []
}

export const porcRespuestasMapper = (data: any): any => {
  return data !== undefined
    && data[0] !== undefined
    && data[0].resume !== undefined
    ? { value: data[0].resume?.answeredPerc }
    : {}
}

export const calidadDatosMapper = (data: any): any => {
  return data !== undefined
    && data[0] !== undefined
    && data[0].resume !== undefined
    ? { value: data[0].resume?.sentPerc }
    : {}
}

export const invalidMapper = (data: any): any => {
  //console.log('Invalid mapper')
  //console.log(data)
  const total = data !== undefined && data
    .map((item: any) => item['count'])
    .reduce((prev: any, curr: any) => prev + curr, 0)

  let invalid: any = data !== undefined
    && data.find((item: any) => item['group'] == 'INVALID')

  invalid = invalid && invalid['count'] !== undefined ? invalid['count'] : 0
  //return { value: total > 0 ? ((total - invalid) / total) * 100 : 0 }
  return total > 0 ? { value: ((total - invalid) / total) * 100, total: total } : []
}

export const yesNoMapper = (data: any) => {
  let yesCounter: any = data.find((item: any) => item['group'] !== undefined
    && item['group'].toUpperCase() === 'SI')
  yesCounter = yesCounter && yesCounter['count'] !== undefined ? yesCounter['count'] : 0

  let noCounter: any = data.find((item: any) => item['group'] !== undefined
    && item['group'].toUpperCase() === 'NO')
  noCounter = noCounter && noCounter['count'] !== undefined ? noCounter['count'] : 0

  const totalCounter = yesCounter + noCounter

  let result: any[] = []

  if (noCounter > 0) {
    result = [
      {
        id: 0,
        group: 'NO',
        label: 'No',
        value: noCounter > 0 ? (noCounter / totalCounter) * 100 : 0
      }
    ]
  }

  if (yesCounter > 0) {
    result = [
      ...result,
      {
        id: 1,
        group: 'SI',
        label: 'Si',
        value: yesCounter > 0 ? (yesCounter / totalCounter) * 100 : 0
      }
    ]
  }

  //return { value: noCounter > 0 ? (yesCounter / noCounter) * 100 : 100 }
  return result
}
//*TODO: refactorizar con yesNoMapper
export const genderMapper = (data: any) => {
  let maleCounter: any = data.find((item: any) => item['group'] !== undefined
    && item['group'].toUpperCase() === 'MASCULINO')
  maleCounter = maleCounter && maleCounter['count'] !== undefined ? maleCounter['count'] : 0

  let femaleCounter: any = data.find((item: any) => item['group'] !== undefined
    && item['group'].toUpperCase() === 'FEMENINO')
  femaleCounter = femaleCounter && femaleCounter['count'] !== undefined ? femaleCounter['count'] : 0

  const totalCounter = maleCounter + femaleCounter

  let result: any[] = []

  if (femaleCounter > 0) {
    result = [
      {
        id: 0,
        group: 'FEMENINO',
        label: 'Femenino',
        value: femaleCounter > 0 ? (femaleCounter / totalCounter) * 100 : 0
      }
    ]
  }

  if (maleCounter > 0) {
    result = [
      ...result,
      {
        id: 1,
        group: 'MASCULINO',
        label: 'Masculino',
        value: maleCounter > 0 ? (maleCounter / totalCounter) * 100 : 0
      }
    ]
  }

  //return { value: noCounter > 0 ? (yesCounter / noCounter) * 100 : 100 }
  return result
}





export const inversePorcMapper = (data: any) => {
  return { value: 100 - data['value'] }
}

export const groupMapper = (data: any) => {
  return data.map((item: any) => ({
    id: item['group'] == null ? 'Indefinido' : item['group'],
    label: item['group'] == null ? 'Indefinido' : item['group'],
    value: item['value'],
    count: item.count
  }))
}

export const groupMapperLateral = (data: any) => {
  return data.map((item: any) => ({
    id: item['group'] == null ? 'Indefinido' : item['group'],
    label:
      item['group'] == null
        ? 'Indefinido'
        : item['group'].substr(0, 35).trim() + '.',
    value: item['value'],
    count: item.count
  }))
}

export const pieColors = [
  '#019E85',
  '#88DD76',
  '#DEE13C',
  '#DB7476',
  '#3f3166',
  '#c878e3',
]



export const grayColors = [
  '#D5DBDB',
  '#E8E8E8',
  '#D8D8D8',
  '#D3D3D3',
  '#C0C0C0',
  '#B0B0B0',
  '#A0A0A0',
  '#888888',
  '#686868',
  '#606060'
]

export const getMitsubishiFilterCriticalMoments = (
  filtersData: Record<string, any>,
  userProfiles: UserProfile[]
): CriticalMoment[] => {

  const profile = userProfiles
    && userProfiles
    && userProfiles[0]
    && userProfiles[0].name
    ? userProfiles[0].name.toLowerCase() : undefined

  let criticalMoments = filtersData.criticalMoments
    && filtersData.criticalMoments[0]
    && filtersData.criticalMoments[0].children
    ? filtersData.criticalMoments[0].children
    : filtersData.criticalMoments

  if (profile && ['cxo', 'demo', 'omoikane'].includes(profile)) {
    const general = {
      id: 0,
      name: 'General',
      description: 'General'
    }
    criticalMoments = [
      general,
      ...criticalMoments,
    ]
  }

  return criticalMoments
}

export const getFilterCriticalMoments = (filtersData: Record<string, any>): CriticalMoment[] => {
  /*const criticalMoments = filtersData.criticalMoments
    && filtersData.criticalMoments[0]
    && filtersData.criticalMoments[0].children
    ? filtersData.criticalMoments[0].children
    : filtersData.criticalMoments*/

  const { getCriticalMomentsRootId } = useClientConfig()

  const generalId = filtersData.criticalMoments
    && filtersData.criticalMoments[0]
    && filtersData.criticalMoments[0].id
    ? filtersData.criticalMoments[0].id
    : getCriticalMomentsRootId()

  const general = {
    id: generalId,
    name: 'Todos',
    description: 'Todos'
  }

  //const cms = criticalMoments.filter((cm: any) => cm.name !== 'TODOS')
  const cms = getChildrens(filtersData.criticalMoments)

  //console.log('Get filter critical moments data')
  //console.log(cms)

  const sortedCms = cms
    // @ts-ignore
    //.asMutable()
    .sort((a: any, b: any) => a.description > b.description
      ? 1 : (a.description < b.description ? -1 : 0))


  // @ts-ignore
  return [general, ...sortedCms,]
}

export const getFilterCriticalMomentDefault = (filtersData: Record<string, any>): number => {
  const criticalMoments = filtersData.criticalMoments
    && filtersData.criticalMoments[0]
    && filtersData.criticalMoments[0].id
    ? filtersData.criticalMoments[0].id
    : 0

  return criticalMoments
}

export const getMitsubishiFilterCriticalMomentDefault = (filtersData: Record<string, any>, userProfiles: UserProfile[]): number => {
  const profile = userProfiles
    && userProfiles
    && userProfiles[0]
    && userProfiles[0].name
    ? userProfiles[0].name.toLowerCase() : undefined

  return profile && ['cxo', 'demo', 'omoikane'].includes(profile) ? 0 : 1
}

export const renderForCriticalMoment = (filters: FiltersUI, cmIncludes: number[]) => {
  return (
    filters !== undefined &&
    filters.criticalMoments !== undefined &&
    filters.criticalMoments !== null &&
    filters.criticalMoments.some(cm => cmIncludes.includes(cm))
  )
}

export const isGeoLocationLeaf = (filters: FiltersUI, filtersData: Record<string, any>) => {
  //console.log('isCriticalMomentLeaf!!!!')
  const geoIncludes = filters?.geographicDistributions
  //console.log(geoIncludes)
  const geos = selectedGeos(filtersData.geographicDistributions, geoIncludes)
  //console.log(geos)
  //console.log(geoIncludes)
  return (
    geos !== undefined &&
    geos.length === 1 &&
    geos[0] !== undefined &&
    geos[0].children.length === 0
  )
}

export const renderForGeoLocation = (filters: FiltersUI, geoIncludes: number[]) => {
  return (
    filters !== undefined &&
    filters.geographicDistributions !== undefined &&
    filters.geographicDistributions !== null &&
    filters.geographicDistributions.some(geo => geoIncludes.includes(geo))
  )
}

const getWidgetTypeConfig = (wdg: Widget): WidgetConfig => {
  const { getDecimals } = useClientConfig()
  const defaultIndicators = wdg.config?.indicators?.map(ind => ({
    ...ind,
    indicator: ind.indicator ?? ind.key,
    //grouped: 'formattedMonth',
    keyExtract: ['passives', 'detractors', 'promoters',
      'passiveCount', 'detractorCount', 'promoterCount',
      'value', 'groupName', 'count'],
    label: 'group',
    value: 'value',
    singleton: false,
    historic: false,
    extras: {
      ...ind.extras,
      //periods: 6
    },
  }))

  const defaultConfigs = {
    ...wdg.config,
    id: wdg.config?.id ?? 'default-id-' + wdg.title,
    showHistoric: false,
    type: 'trend',
    decimals: getDecimals(),
    indicators: defaultIndicators
  }

  if (wdg.config?.type === 'nps') {
    return {
      ...defaultConfigs,
      type: 'semicircle'
    }
  }

  return defaultConfigs
}

export const configBackDashboards = (props: {
  backDashboards?: Record<string, DasboardConfig>,
  staticDashboards?: Record<string, DasboardConfig>,
  filters: FiltersUI,
}): Widget[] => {

  const { filters, backDashboards, staticDashboards } = props

  //console.log('config back dashboards!!!!')
  //console.log(props)

  let newBacks = {}
  backDashboards !== undefined
    && Object.keys(backDashboards).forEach((key: string) => {
      const dash = backDashboards[key]
      newBacks[key] = {
        ...dash,
        dashType: 'back'
      }
    })

  let newStatics = {}
  staticDashboards !== undefined
    && Object.keys(staticDashboards).forEach((key: string) => {
      const dash = staticDashboards[key]
      newStatics[key] = {
        ...dash,
        dashType: 'static'
      }
    })

  const allDashboards = {
    ...newBacks,
    ...newStatics
  }

  if (allDashboards === undefined || allDashboards === null || Object.keys(allDashboards).length === 0) {
    return []
  }

  return Object.keys(allDashboards)
    .filter(key => allDashboards[key].widgets.length > 0)
    .map((key: string) => {
      const dash = allDashboards[key]
      const criticalMomentId = Number(key)
      let widgets: Widget[] = []

      //console.log('Config all dashboards para ')
      //console.log(dash)

      if (isNumber(criticalMomentId)) {
        const dashWidgets = dash.dashType === 'back'
          ? dash.widgets
          : dash.widgets(filters)

        widgets = [
          ...dashWidgets
            .map((wdg: any) => {
              const widgetConfig = dash.dashType === 'back'
                ? getWidgetTypeConfig(wdg)
                : wdg.config
              return {
                ...wdg,
                config: widgetConfig,
                render: (filters: FiltersUI) => renderForCriticalMoment(filters, [criticalMomentId]),
              }
            }),
        ]
      }

      return widgets
    })
    .filter((item: any) => (item !== undefined || _.isEmpty(item)))
    .flat(1)
}
