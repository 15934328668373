import cxoResume from './cxoResume'
import nacionalPosventa from './nacionalPosventa'
import nacionalReparacion from './nacionalReparacion'
import nacionalVenta from './nacionalVenta'

export default {
  title: 'CXO Venta/Postventa Dashboard',
  description: 'Dashboard de CXO Venta/Postventa',
  backgroundClassName: (filters: any) => {
    if (filters !== undefined &&
      filters.criticalMoments !== undefined &&
      filters.criticalMoments !== null &&
      (filters.criticalMoments.includes(1)
        || filters.criticalMoments.includes(2)
        || filters.criticalMoments.includes(3))) {
      return 'epsilon-hero-bg'
    }

    return 'default'
  },
  widgets: (filters: FiltersUI) => [
    ...cxoResume.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) =>
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0),
      })),
    ...nacionalVenta.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) =>
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(1),
      })),
    ...nacionalPosventa.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) =>
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(2),
      })),
    ...nacionalReparacion.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) =>
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(3),
      })),
  ],
} as DasboardConfig
