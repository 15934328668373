import Immutable from 'seamless-immutable'
import { pieColors } from '../dashHelper'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'
import { getLeavesByField, selectedGeos } from '@utils/GeoUtils'
import { COLORS_CONFIG } from '@components/widgets/apacheEchart/Commons'

const QUARTER_KEYS = {
  'durante_a_pregunta_13': 'Alimentos',
  'durante_a_pregunta_33': 'Trato',
  'durante_a_pregunta_11': 'Atención telefónica',
  'durante_a_pregunta_24': 'Trato enfermeria',
  'durante_a_pregunta_8': 'Instalaciones',
}

export const haTrendDataMapper = (data: any, extra?: any) => {
  //console.log('haTrendDataMapper!!!')
  //console.log(data)
  if (data === undefined || data.length === 0) {
    return data
  }

  const geoLeaves: string[] = getLeavesByField(extra.geoLocations[0], 'name')

  const root = data.filter((item: any) => item.group === null)

  const regions = Immutable.asMutable(data)
    .filter((item: any) => item.group !== null && !geoLeaves.includes(item.group))
    .sort((item1: any, item2: any) => item2.value - item1.value)

  const leaves = Immutable.asMutable(data)
    .filter((item: any) => geoLeaves.includes(item.group))
    .sort((item1: any, item2: any) => item2.value - item1.value)

  const finalData = [
    ...root,
    ...regions,
    ...leaves
  ].map((item: any) => ({ ...item, group: item.group ?? 'General' })).slice(0, 5)

  //console.log(finalData)

  return finalData.reverse()
}

export const yesNoMapperHa = (data: any) => {
  if (data === undefined || data.length === 0) {
    return data
  }

  const finalData = data
    .map((item: any) => ({
      ...item,
      groupName: item.group === '0' ? 'Si' : 'No',
      label: item.group === '0' ? 'Si' : 'No'
    }))
    //@ts-ignore
    .asMutable()
    //@ts-ignore
    .sort((item1, item2) => item1.group === '0' ? -1 : 1)

  return finalData
}

export const haLineDetailedByMonthMapper = (data: any, extra: any) => {
  // console.info('haLineDetailedByMonthMapper')
  //console.info(data)
  const metrics = [
    { label: 'General', value: data['data-gral'] },
    ...data['grouped-by-critical-moment']?.map((item: any) => ({
      label: item.group,
      value: item.value
    })),
    ...data['grouped-by-geo']?.map((item: any) => ({
      label: item.group,
      value: item.value
    }))
  ]
  const dataMonth = data['grouped-by-month']?.map((item: any, idx: number) => ({
    ...item,
    id: idx,
    //category: item.groupName ? item.groupName : item['group_0'],
    //group: item['group_1'] ? item['group_1'] : item.label,
    category: item.groupName ? item.groupName : item['group_0'],
    groupName: item['group_1'] ? item['group_1'] : item.label,
  }));

  const dataGral = data['grouped-by-month-gral']?.map((item: any, idx: number) => ({
    ...item,
    id: idx,
    category: item.groupName ? item.groupName : item['group_0'],
    groupName: 'General',
  }));



  return {

    value: [...dataMonth, ...dataGral],
    metrics: metrics
  }
}

export const haLineDetailedByGeoMapper = (data: any, extra: any) => {
  //console.log('haLineDetailedByGeoMapper');
  //console.log(haLineDetailedByGeoMapper);
  const metrics = [
    { label: 'General', value: data['data-gral'] },
    ...data['grouped-by-critical-moment']?.map((item: any) => ({
      label: item.group,
      value: item.value
    })),
    ...data['grouped-by-geo']?.map((item: any) => ({
      label: item.group,
      value: item.value
    }))
  ]
  const dataMonth = data['grouped-by-month']?.map((item: any, idx: number) => ({
    ...item,
    id: idx,
    category: item.groupName ? item.groupName : item['group_0'],
    groupName: item['group_1'] ? item['group_1'] : item.label,
  }));

  const dataGral = data['grouped-by-month-gral']?.map((item: any, idx: number) => ({
    ...item,
    id: idx,
    category: item.groupName ? item.groupName : item['group_0'],
    groupName: 'General',
  }));

  return {
    value: [...dataMonth, ...dataGral],
    metrics: metrics
  }
}

export const haBarDetailedByMonthMapper = (data: any, extra: any) => {
  const metrics = [
    { label: 'General', value: data['data-gral'] },
    ...data['grouped-by-critical-moment']?.map((item: any) => ({
      label: item.group,
      value: item.value
    }))
  ];

  const sortedValue = [...data['grouped-by-geo']].map((item: any, idx: number) => {
    var groupName = item.groupName ? item.groupName : item.group;
    groupName = groupName.replaceAll('Hospital ', '');
    return {
      ...item,
      id: idx,
      value: item.value,
      group: groupName,
      label: groupName
    };
  }).sort((a, b) => b.value - a.value);

  return {
    value: sortedValue,
    metrics: metrics
  };
};


export const haLeafLineDetailedByGeoMapper = (data: any) => {
  console.log('haLeafLineDetailedByGeoMapper!!!!!!!!')
  console.log(data)


  const metrics = [
    { label: 'General', value: data['data-gral'] },
    ...data['grouped-by-geo']?.map((item: any) => ({
      label: item.group,
      value: item.value
    })),
    ...data['grouped-by-geo-leaf']?.map((item: any) => ({
      label: item.group,
      value: item.value
    }))
  ]

  /*.asMutable()
    .sort((a: any, b: any) => b['group_0'] > a['group_0'] ? -1 : 1)*/

  return {
    //@ts-ignore
    value: data['grouped-by-month-and-geo']?.map((item: any, idx: number) => ({
      ...item,
      id: idx,
      //category: item.groupName ? item.groupName : item['group_0'],
      //group: item['group_1'] ? item['group_1'] : item.label,
      category: item.groupName ? item.groupName : item['group_0'],
      groupName: item['group_1'] ? item['group_1'] : item.label,
    })),
    metrics: metrics
  }
}


export const haSingleLineDetailedByGeoMapper = (data: any, extra: any) => {
  const finalData = data['grouped-by-month-and-geo']
    .map((item: any, idx: number) => ({
      ...item,
      id: idx,
      category: item.groupName ? item.groupName : item.group,
      groupName: item.zoomDescription ?? 'General',
    }));

  const metrics = [
    ...data['grouped-by-geo']?.map((item: any) => ({
      label: item.group ?? 'General',
      value: item.value
    }))
  ]

  return {
    value: finalData,
    metrics: metrics
  }
}

export const haWordCountWithLinksMapper = (data: any) => {
    return data.map((item: any) => ({
        ...item,
        tags: [...item.tags].filter((tag: any) => tag.group !== 'DEFAULT').sort((a: any, b: any) => b.count - a.count).slice(0, 10)
    }))

}

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  haLineDetailedByMonthAndGeo: {
    title: 'Satisfacción General',
    // [Column, Row]
    position: [0, 0],
    size: [4, 3],
    config: {
      id: 'line-detailed-by-month-and-geo',
      type: 'lineDetailed',
      //fillMode: 'gradient',
      fillMode: '',
      colors: pieColors,
      showValues: false,
      //percentageScale: false,
      shadow: false,
      indicators: [
        {
          key: 'grouped-by-month',
          indicator: 'FALTA',
          grouped: 'formattedMonth,geoLocation',
          dataType: 'single',
          keyExtract: [
            'group_0',
            'group_1',
            'groupId_0',
            'value',
            'groupName'
          ],
          label: 'group',
          singleton: true,
          sort: { sortBy: 'group_0', value: 'asc' },
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
        {
          key: 'grouped-by-month-gral',
          indicator: 'FALTA',
          grouped: 'formattedMonth,geoLocation',
          dataType: 'single',
          keyExtract: [
            'group_0',
            'group_1',
            'groupId_0',
            'value',
            'groupName'
          ],
          label: 'group',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description',
            groupByLevel: 0
          },
        },
        {
          key: 'data-gral',
          indicator: 'FALTA',
          keyExtract: ['value'],
          value: 'value',
          dataType: 'single',
          singleton: false,
          extras: {},
        },
        {
          key: 'grouped-by-critical-moment',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'criticalMoment',
          dataType: 'single',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
        {
          key: 'grouped-by-geo',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'geoLocation',
          dataType: 'single',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
      ],
    },
  },
  haBarDetailedByMonthAndGeo: {
    title: 'Satisfacción General',
    // [Column, Row]
    position: [0, 0],
    size: [4, 3],
    config: {
      id: 'bar-detailed-by-geo',
      type: 'barDetailed',
      //fillMode: 'gradient',
      fillMode: '',
      colors: COLORS_CONFIG,
      showValues: false,
      wrapTexts: true,
      //percentageScale: false,
      shadow: false,
      rotateXAxisLegend: true,
      indicators: [
        {
          key: 'grouped-by-geo',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'geoLocation',
          dataType: 'single',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
            groupByLevel: 2
          },
        },
        {
          key: 'grouped-by-geo-gral',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'geoLocation',
          dataType: 'single',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
            groupByLevel: 0
          },
        },
        {
          key: 'data-gral',
          indicator: 'FALTA',
          keyExtract: ['value'],
          value: 'value',
          dataType: 'single',
          singleton: false,
          extras: {},
        },
        {
          key: 'grouped-by-critical-moment',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'criticalMoment',
          dataType: 'single',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
      ],
    },
  },
  haLeafLineDetailedByMonthAndGeo: {
    ...widgetsLibrary.lineDetailedByMonthAndGeo,
    //@ts-ignore
    config: {
      ...widgetsLibrary.lineDetailedByMonthAndGeo.config,
      decimals: 0,
      indicators: [
        {
          key: 'grouped-by-month-and-geo',
          indicator: 'FALTA',
          grouped: 'formattedMonth,geoLocation',
          keyExtract: [
            'group_0',
            'group_1',
            'groupId_0',
            'value',
            'groupName'
          ],
          label: 'group',
          value: 'value',
          sort: { sortBy: 'group_0', value: 'asc' },
          singleton: true,
          extras: {
            periods: 6
          },
        },
        {
          key: 'data-gral',
          indicator: 'FALTA',
          keyExtract: ['value'],
          value: 'value',
          dataType: 'single',
          singleton: false,
          extras: {},
        },
        {
          key: 'grouped-by-geo',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'geoLocation',
          dataType: 'single',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
            groupByLevel: 1
          },
        },
        {
          key: 'grouped-by-geo-leaf',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'geoLocation',
          dataType: 'single',
          singleton: true,
          extras: {
            groupNameFormat: 'description'
          },
        }
      ],
    }
  },
  haSingleLineDetailedByMonthAndGeo: {
    ...widgetsLibrary.lineDetailedByMonthAndGeo,
    //@ts-ignore
    config: {
      ...widgetsLibrary.lineDetailedByMonthAndGeo.config,
      decimals: 0,
      indicators: [
        {
          //key: 'grouped-by-month-and-geo-leaf',
          key: 'grouped-by-month-and-geo',
          indicator: 'FALTA',
          grouped: 'formattedMonth',
          keyExtract: [
            'group',
            'group_0',
            'group_1',
            'groupId_0',
            'value',
            'groupName',
            'zoomDescription'
          ],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            zoomDimension: "geoLocation",
            featureInclude: ['zoomOut', 'groupBySelfInclude'],
            groupNameFormat: 'description',
            maxGroupByLevel: 2,
            periods: 6
          },
        },
        {
          key: 'grouped-by-geo',
          indicator: 'FALTA',
          grouped: 'geoLocation',
          keyExtract: ['value', 'groupName', 'count', 'group'],
          dataType: 'single',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
            featureInclude: ['zoomOut']
          },
        }
      ],
    }
  },
  haTrendByGeo: {
    title: 'FALTA',
    // [Column, Row]
    position: [0, 2],
    size: [1, 2],
    config: {
      id: 'generic-trend-by-geo',
      type: 'trend',
      color: '#f38625',
      valueSymbol: '%',
      showHistoric: false,
      showSample: true,
      showTrend: false,
      decimals: 2,
      multiIndicator: true,
      indicators: [
        {
          indicator: 'FALTA',
          grouped: 'geoLocation',
          keyExtract: ['value', 'groupName', 'count', 'group'],
          value: 'value',
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {
            featureInclude: ['zoomOut'],
            //featureInclude: ['zoomOut', 'groupBySelfInclude'],
            //maxGroupByLevel: 2
          },
        }
      ],
    },
  },
  quarterTrend: {
    title: 'FALTA',
    // [Column, Row]
    position: [0, 2],
    size: [1, 2],
    config: {
      id: 'quarter-trend',
      type: 'quarter',
      color: '#f38625',
      valueSymbol: '%',
      showHistoric: false,
      showSample: false,
      showTrend: false,
      decimals: 2,
      axisYTitleMarginTop: '100%',
      cellType: 'bubble',
      categories: ['¿Cómo lo hacemos?', 'Impacto en la satisfacción del cliente'],
      mapper: (data: any) => {

        const correlaciones = data['correlaciones']
        const valores = data['valores']

        var legends = {}
        var tooltips = {}
        var finalData = {}
        correlaciones.map((correlacion: any, idx: number) => {
          const groupName = correlacion.groupName
          const key = correlacion.group
          //var valueX = correlacion['value_norm']
          var valueY = valores.find((item: any) => item.groupId === key)?.value

          //const legendSplit = correlacion.groupName.split('_')

          tooltips[groupName] = correlacion.description
          legends[groupName] = [QUARTER_KEYS[groupName], valueY && (valueY.toFixed(1) + '%')]


          var calulatedMedianX = correlacion['calulatedMedian']
          var calulatedMedianY = valores.find((item: any) => item.groupId === key)?.calulatedMedian
          var calulatedMedianXValue = calulatedMedianX == -1 ? 1 : (calulatedMedianX == 1 ? 3 : 2)
          var calulatedMedianYValue = calulatedMedianY == -1 ? 1 : (calulatedMedianY == 1 ? 3 : 2)

          finalData[groupName] = [calulatedMedianXValue, calulatedMedianYValue]
        })

        return {
          values: finalData,
          tooltips: tooltips,
          legends: legends
        }
      },
      indicators: [
        {
          indicator: 'CORRELATION',
          key: 'correlaciones',
          dataType: 'list',
          keyExtract: [
            'groupName', 'count', 'description',
            'value', 'group', 'value_norm', 'calulatedMedian'
          ],
          value: 'value',
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {
            correlationTarget: 10879,
            sort: "asc",
            normalize: true,
            normalize_a: "25",
            normalize_b: "75",
            calulateMedian: true
          },
        },
        {
          indicator: 'CORRELATION_VALUES',
          key: 'valores',
          grouped: 'question',
          dataType: 'list',
          keyExtract: [
            'fanCount', 'detractorCount',
            'count', 'passiveCount',
            'promoterCount', 'group',
            'value', 'promoters', 'detractors',
            'fans', 'passives', 'promoterNoFanCount',
            'promotersNoFan', 'groupId', 'calulatedMedian'
          ],
          value: 'value',
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {
            calulateMedian: true
          },
        }
      ],
    },
  },
  haSentimentPieNPS: {
    title: 'FALTA',
    // [Column, Row]
    position: [1, 48],
    size: [2, 2],
    config: {
      id: 'haSentimentPieNPS',
      type: 'pie',
      fillMode: '',
      colors: ['#a2d1a2', '#f5b49f', '#a3d1d6'],
      showValues: true,
      showSample: true,
      mapper: (data: any) => {
        //console.log('haSentimentPieNPS -> data:');
        //console.log(data);
        const acumValues = {
          count: 0,
          positive: 0,
          negative: 0,
          neutral: 0
        }


        data.forEach((reg: any) => {
          acumValues.count = acumValues.count + Number(reg.count)
          acumValues.positive = acumValues.positive + Number(reg.positive)
          acumValues.negative = acumValues.negative + Number(reg.negative)
          acumValues.neutral = acumValues.neutral + Number(reg.neutral)
        })

        return [{
          label: 'Positivo',
          value: acumValues.count ? acumValues.positive * 100 / acumValues.count : 0
        },
        {
          label: 'Negativo',
          value: acumValues.count ? acumValues.negative * 100 / acumValues.count : 0
        },
        {
          label: 'Neutral',
          value: acumValues.count ? acumValues.neutral * 100 / acumValues.count : 0
        }];

      },
      itemWidth: 80,
      itemHeight: 20,
      //valueFormat: ' >-.1%',
      arcLabelsRadiusOffset: 1.15,
      itemDirection: 'left-to-right',
      anchor: 'top-left',
      direction: 'column',
      marginLeft: 250,
      legendTranslateX: -200,
      decimals: 2,
      indicators: [
        {
          indicator: 'SENTIMENT',
          grouped: 'criticalMoment,tag',
          keyExtract: ['group', 'count', 'positive', 'negative', 'neutral'],
          label: 'group',
          value: 'count',
          singleton: true,
          extras: {
            //groupBy:['criticalMoment']
          },

        },
      ],
    },
  },
  haGeoLocationPie :
  {
        title: "Seleccione el Hospital de procedencia.",
        position: [1, 1],
        size: [3, 2],
        config: {
          id: "laboratorio_servicios_subrogados_pregunta_99",
          type: "pie",
          //colors: ["#015c52", "#33b9ce", "#f38625", "#8bde26", "#f9b50b"],
          showHistoric: false,
          //marginTop: 10,
          //marginBottom: 20,
          //marginLeft: 50,
          //marginRight: 0,
          legendTop: 'top',
          itemDirection: "left-to-right",
          direction: "column",
          valueFormat: " >-.0%",
          //center: ['40%', '65%'],
          //radius: [25, 60],
          indicators: [
            {
              indicator: "laboratorio_servicios_subrogados_pregunta_99",
              keyExtract: ["group", "count", "value"],
              label: "group",
              value: "value",
              singleton: true,
              grouped: "geoLocation",
              extras: {
                useQuestionOptions: true,
                groupByLevel: 2
              }
            }
          ]
        }
      },

}

