import { renderForCriticalMoment, renderForGeoLocation } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, haBarDetailedByMonthMapper, haLeafLineDetailedByGeoMapper, haLineDetailedByGeoMapper, haLineDetailedByMonthMapper, haSingleLineDetailedByGeoMapper } from './commonWidgetsHa'


export const quarterColorsOld = [
  ['#F4F8F4', '#FFD9B7', '#f76d6d'],
  ['#DCF1DE', '#F4F8F4', '#FFD9B7'],
  ['#BBE4BF', '#DCF1DE', '#F4F8F4'],
]
export const quarterColors = [
  ['#F57B51', '#FAB68A', '#B5EB85'],
  ['#FAB68A', '#F8DDD3', '#C6EBA4'],
  ['#F8DDD3', '#C6EBA4', '#CBECC3'],
]

const allWidgets = [
  /**
   * NPS
   */
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haSingleLineDetailedByMonthAndGeo',
    title: 'NPS General por mes',
    position: [0, 0],
    size: [4, 4],
    extraConfigs: {
      backgroundColor: 'transparent',
      theme: 'dark',
    },
    // @ts-ignore
    mapper: haSingleLineDetailedByGeoMapper,
    indicator: 'NPS_GENERAL',
    key: 'nps-line-detailed-single',
  }),
  /**
   * Satisfacción
   */
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haSingleLineDetailedByMonthAndGeo',
    title: 'Satisfacción General por mes',
    position: [0, 4],
    size: [4, 4],
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    },
    // @ts-ignore
    mapper: haSingleLineDetailedByGeoMapper,
    indicator: 'CSAT_GENERAL',
    key: 'csat-line-detailed-single',
  }),
  {
    title: 'Matriz de Priorización',
    type: 'label',
    position: [0, 8],
    size: [4, 1],
    criticalMomentInclude: [10301]
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'quarterTrend',
    position: [0, 9],
    extraConfigs: {
      colors: quarterColors
    },
    size: [4, 3],
  }),
    getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haSentimentPieNPS',
    title: 'Análisis de sentimientos',
    position: [0, 12],
    size: [2, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCountWithLinks',
    title: 'Nube de Palabras / Frecuencia en comentarios',
    position: [2, 12],
    size: [2, 2],
    mapper: (data, extra) => {
    return data.map((item: any) => ({
        ...item,
        tags: [...item.tags].filter((tag: any) => tag.group !== 'DEFAULT').sort((a: any, b: any) => b.count - a.count).slice(0,10)
      }))
    },
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    position: [0, 14],
    size: [4, 3],
    indicator: 'SENTIMENT'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'diagramaIshikawa',
    title: 'Diagrama Ishikawa',
    position: [0, 17],
    size: [4, 3],
    indicator: 'SENTIMENT'
  }),

]

export default {
  title: 'Default Dashboard',
  description: 'Dashboard de perfil Default',
  widgets: (filters: FiltersUI) => allWidgets,
} as DasboardConfig
