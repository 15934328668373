export const filterGeos = (geos: GeoDistribution[], selected: number[]): number[] => {
  const geosArray: any = geos.map((geo) => {
    return selected.includes(geo.id)
      ? getLeaves(geo)
      : filterGeos(geo.children, selected)
  })
  return [].concat(...geosArray)
}

const getLeaves = (geos: GeoDistribution) => {
  return getLeavesByField(geos, 'id')
}

export const getLeavesByField = (geos: GeoDistribution, field: string) => {
  const leaves: any =
    geos.children === undefined || geos.children.length === 0
      ? [geos[field]]
      : geos.children.map((child) => getLeavesByField(child, field))
  return [].concat(...leaves)
}

export const selectedGeos = (geos: GeoDistribution[], selected: number[]): GeoDistribution[] => {
  const geosArray: any = geos.map((geo) => {
    return selected.includes(geo.id)
      ? geo
      : selectedGeos(geo.children, selected)
  })
  return [].concat(...geosArray)
}
